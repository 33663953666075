import React from 'react';
import * as Yup from "yup";
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { Box, Button, Dialog, Typography, styled, withStyles, IconButton } from '@material-ui/core';
import SwitchInput from "@material-ui/core/Switch";
import { Amex, DefaultCard, Mastercard, Visa } from '../../../blocks/scheduling/src/assets';
import InputField from '../InputField';
import { inputFilter } from '../helpers';
import { LoadingButton } from '../HelperComponents';
import CloseIcon from "@material-ui/icons/Close";

interface ICardFields {
    name: string,
    number: string,
    valid_till: string,
    save_for_future: boolean
}

const initialValues = (repeat_weekly: boolean | undefined): ICardFields => {
    return {
        name: "",
        number: "",
        valid_till: "",
        save_for_future: repeat_weekly ? true : false
    }
}

const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Please enter name"),
    number: Yup.string().trim()
        .min(18, 'Please enter full card number')
        .required('Please enter valid Card number'),
    valid_till: Yup.string()
        .required('Please enter valid expiry')
        .matches(/^(0[1-9]|1[0-2]) \/ \d{4}$/, 'Invalid expiry format (MM / YYYY)')
        .test('valid-year', 'Please enter valid Year', value => {
            if (!value) return false;
            const [month, year] = value.split('/').map((part: string) => part.trim());
            const currentYear = new Date().getFullYear();
            const yearNum = parseInt(year, 10);
            return yearNum >= currentYear;
        })
});

const styles = {
    input: {
        height: '34px',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Montserrat',
    }
}

type IProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (values: ICardFields) => void;
    error: string;
    clearError: () => void;
    submitLoader: boolean;
    isRepeatWeekly?: boolean 
}

const AddCardModal = (props: IProps) => {
    const { isOpen, onClose, onSubmit, error, clearError, submitLoader } = props;

    const getCardProvider = (number: string) => {
        const cardNumber = number.replace(/\s/g, '');
        if (cardNumber.startsWith('4')) return Visa;
        if (/^5[1-5]/.test(cardNumber)) return Mastercard;
        if (/^3[47]/.test(cardNumber)) return Amex;
        return DefaultCard;
    };

    const getInputProps = <T extends object>(
        field: keyof T,
        errors: FormikErrors<T>,
        touched: FormikTouched<T>,
        values: T,
        isEdit: boolean
    ) => {
        return {
            id: field,
            name: field,
            fullWidth: true,
            error: Boolean(errors[field]) && touched[field],
            helperText: (touched[field] && errors[field])?.toString(),
            labelStyle: {
                color: '#777777',
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: "Montserrat",
                lineHeight: '17px'
            }
        };
    };
    const ErrorBox = () => {
        return (
            <>
                {error ?
                    <Box className="error-box">
                        {error}
                        <ErrorCloseButton onClick={clearError}>
                            <ErrorCloseIcon />
                        </ErrorCloseButton>
                    </Box>
                    : <></>}
            </>
        )
    }

    return (
        <DialogWrapper
            id='add-card'
            open={isOpen}
            fullWidth
            aria-labelledby="add-card-id"
        >
            <ContentWrapper>
                <Title>Card details</Title>
                <SubTitle>Update your payment cards</SubTitle>
                <Formik
                    initialValues={initialValues(props.isRepeatWeekly)}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values) => onSubmit(values)}
                    data-test-id="reset-email-form"
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        getFieldProps,
                        setFieldValue,
                        resetForm,
                    }) => {
                        return (
                            <FormWrapper onSubmit={handleSubmit}>
                                {ErrorBox()}
                                <Box className='fields'>                                    
                                    <Box>
                                        <CustomLabel>Name</CustomLabel>
                                        <InputField
                                            placeholder={'The name on the card'}
                                            {...getFieldProps("name")}
                                            inputProps={{ style: styles.input }}
                                            {...getInputProps("name", errors, touched, values, true)}
                                            onChange={(event) => {
                                                setFieldValue('name', inputFilter.applyFilter('lettersSpaces', event.target.value))
                                                clearError();
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <CustomLabel>Card Number</CustomLabel>
                                        <InputField
                                            {...getFieldProps("number")}
                                            placeholder={'XXXX XXXX XXXX XXXX'}
                                            inputProps={{ style: styles.input }}
                                            {...getInputProps("number", errors, touched, values, true)}
                                            onChange={(event) => {
                                                setFieldValue('number', inputFilter.applyFilter('cardNumber', event.target.value))
                                                clearError();
                                            }}
                                            endAdornment={<img src={getCardProvider(values.number)} />}
                                        />
                                    </Box>
                                    <Box style={{ width: '150px' }}>
                                        <CustomLabel>Valid Till</CustomLabel>
                                        <InputField
                                            {...getFieldProps("valid_till")}
                                            placeholder={'Month / Year'}
                                            inputProps={{ style: styles.input, maxLength: "9" }}
                                            {...getInputProps("valid_till", errors, touched, values, true)}
                                            onChange={(event) => {
                                                setFieldValue('valid_till', inputFilter.applyFilter('valid', event.target.value))
                                                clearError();
                                            }}
                                            fullWidth={true}
                                        />
                                    </Box>
                                    <Box>
                                        <CustomLabel>Save for future use</CustomLabel>
                                        <CustomSwitch checked={values.save_for_future} onChange={(event) => setFieldValue('save_for_future', event.target.checked)} name="checkedB" />
                                    </Box>
                                    <Box className="box-wrapper">
                                        <FormButton className="secondary-btn" onClick={() => onClose()}>Cancel</FormButton>
                                        <FormSaveButton isLoading={submitLoader} buttonText='Save' type='submit'>Save</FormSaveButton>
                                    </Box>
                                </Box>
                            </FormWrapper>
                        )
                    }}
                </Formik>
            </ContentWrapper>
        </DialogWrapper>
    )
}
const DialogWrapper = styled(Dialog)({
    "& .MuiDialog-container": {
        "& .MuiDialog-paper": {
            maxWidth: 630,
        },
    },
})
const ContentWrapper = styled(Box)({
    padding: "40px 60px",
    "@media (max-width: 960px)": {
        padding: "40px 48px",
    },
    "@media (max-width: 600px)": {
        padding: "32px",
    },
})
const Title = styled(Typography)({
    fontFamily: "Montserrat",
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: "32px",
    color: '#6F05EA',
    marginBottom: '16px',
})
const SubTitle = styled(Typography)({
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: "32px",
    color: '#000',
})
const CustomLabel = styled(Typography)({
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: "22px",
    color: '#000',
})
const FormButton = styled(Button)({
    backgroundColor: 'transparent',
    backround: 'transparent',
    textTransform: 'none',
    letterSpacing: 0,
    padding: '16px 32px',
    borderRadius: '40px',
    fontSize: '24px',
    lineHeight: '24px',
    fontFamily: 'Montserrat',
    "@media (max-width: 1600px)": {
        fontSize: "20px",
    },
})
const FormSaveButton = styled(LoadingButton)({
    backgroundColor: 'transparent',
    backround: 'transparent',
    textTransform: 'none',
    letterSpacing: 0,
    padding: '16px 32px',
    borderRadius: '40px',
    fontSize: '24px',
    lineHeight: '24px',
    fontFamily: 'Montserrat',
    border: "2px solid #6F05EA",
    color: '#6F05EA',
    width: '100%',
    fontWeight: 700,
    "@media (max-width: 1600px)": {
        fontSize: "20px",
    },
})
const FormWrapper = styled('form')({
    margin: "34px 0 0 0",
    "& .box-wrapper": {
        display: "flex",
        gap: "26px",
        alignItems: 'center',
        justifyContent: 'center'
    },
    "& .fields": {
        marginTop: "24px",
        display: 'flex',
        flexDirection: 'column',
        gap: '40px'
    },
    "& .secondary-btn": {
        border: "2px solid #4B4B4B",
        color: '#4B4B4B',
        width: '100%',
        fontWeight: 600
    },
    "& .error-box": {
        display: 'flex',
        minHeight: "40px",
        justifyContent: "space-between",
        alignItems: "center",
        padding: '8px 16px',
        borderLeft: '4px solid #DC2626',
        backgroundColor: '#FEE2E2',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 400,
        color: '#DC2626',
        borderRadius: "4px"
    }
})
const ErrorCloseButton = styled(IconButton)({
    padding: 0,
    "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
})
const ErrorCloseIcon = styled(CloseIcon)({
    height: 16,
    width: 16,
    color: "#DC2626"
})

const CustomSwitch = withStyles({
    root: {
        width: 48,
        height: 27,
        padding: 0,
        margin: "10px 0 0 0",
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(21px)',
            color: '#fff',
            '& + $track': {
                backgroundColor: '#6F05EA',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#6F05EA',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 40,
        border: '1px solid #E2E8F0',
        backgroundColor: '#E2E8F0',
        opacity: 1,
        transition: 'background-color 0.2s, border 0.2s',
    },
    checked: {},
    focusVisible: {},
})(SwitchInput);
export default AddCardModal;