// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { checkCondition, defaultScroll, getCardProvider, getDateAfterWeek, isSameDate, returnTruthyString } from "../../../../components/src/HelperUtils";
import { ICard, ICardResponse, ICreateBookingMeta, ICreatePaymentId, ICustomerPet, IPaymentMethod, Price } from "../../../../components/src/interfaces.web";
import moment from "moment";

const initialCard: ICard = {
    id: "",
    object: "",
    address_city: null,
    address_country: null,
    address_line1: null,
    address_line1_check: null,
    address_line2: null,
    address_state: null,
    address_zip: null,
    address_zip_check: null,
    brand: "",
    country: "",
    customer: "",
    cvc_check: null,
    dynamic_last4: null,
    exp_month: 0,
    exp_year: 0,
    fingerprint: "",
    funding: "",
    last4: "",
    metadata: {
        card_number: "",
        save_for_future_use: ""
    },
    name: "",
    tokenization_method: null,
    wallet: null
}

interface IComplementary {
    checked: boolean,
    service: null | Price
}
type TDayvalue = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday"
interface IRepeatDays {
    label: string,
    value: TDayvalue,
    checked: boolean
}
interface IBookingData {
    pet_details: ICustomerPet | undefined,
    start_date: Date | null | string,
    end_date: Date | null | string,
    service_name: string,
    sub_service: Price | null,
    show_complementary: boolean,
    medication: IComplementary | null,
    transportation: IComplementary | null,
    total_price: number,
    hotel_nights: number | undefined,
    establishment_name: string,
    is_repeat_weekly: boolean,
    createBookingResponse: ICreateBookingMeta | null,
    selected_repeat_days: IRepeatDays[]
}
interface IAddCardBody {
    "name": string,
    "number": string,
    "valid_till": string,
    "save_for_future": boolean
}
interface ICardError {
    error: string;
}
interface ICardMessage {
    message: string;
}
interface IStripeCard {
    "errors": [
        { "stripe": string }
    ]
}
type IAddCardResponse = ICard | ICardError | ICardMessage;

type ICreatePaymentResponse = ICreatePaymentId | ICardMessage | ICardError | IStripeCard
// Customizable Area End
export const webConfigJSON = require("../config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    handleCloseCheckoutPage: () => void,
    BookingData: IBookingData
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    apiToken: string,
    selectedCard: ICard | null,
    selectedPaymentId: IPaymentMethod | null
    // MODALS
    openAddCard: boolean,
    errorAddCard: string,
    openCVVmodal: boolean,
    // API
    cardResponse: ICardResponse | null,
    cardList: ICard[],
    cardListLoading: boolean,
    addCardLoading: boolean,
    getPaymentIdsLoading: boolean,
    getPaymentIdsList: ICreatePaymentId[],
    createPaymentIdLoading: boolean,
    makePaymentApiLoading: boolean,
    paymentSuccess: boolean | null,
    paymentFailure: boolean | null,
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class BookingCheckoutController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    cardListApiCallId: string = ""
    addCardApiCallId: string = ""
    getPaymentIdsApiCallId: string = ""
    createPaymentIdApiCallId: string = ""
    makePaymentApicallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            apiToken: localStorage.getItem("login_token") || "",
            selectedCard: null,
            selectedPaymentId: null,
            // MODALS
            openAddCard: false,
            errorAddCard: "",
            openCVVmodal: false,
            // API
            cardResponse: null,
            cardList: [],
            cardListLoading: false,
            addCardLoading: false,
            getPaymentIdsLoading: false,
            getPaymentIdsList: [],
            createPaymentIdLoading: false,
            makePaymentApiLoading: false,
            paymentSuccess: null,
            paymentFailure: null,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.cardListApiCallId:
                        this.cardListApiResp(responseJson);
                        break;
                    case this.addCardApiCallId:
                        this.addCardApiResp(responseJson);
                        break;
                    // PAYMENTS
                    case this.getPaymentIdsApiCallId:
                        this.getPaymentIdsApiResp(responseJson)
                        break;
                    case this.createPaymentIdApiCallId:
                        this.createPaymentIdApiResp(responseJson)
                        break;
                    case this.makePaymentApicallId:
                        this.makePaymentApiResp(responseJson, errorResponse)
                        break;
                    default:
                        break;
                }
            }
            if (errorResponse) {
                this.resetPaymentStates()
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    cardListApiResp = (responseJson: ICardResponse) => {
        this.setState({ cardListLoading: false })
        if (responseJson && responseJson.customer && responseJson.cards) {
            const alaredySelectedCard = checkCondition(Boolean(this.state.selectedCard), this.state.selectedCard, null) as ICard | null
            const defaultSelectedCard = checkCondition(Boolean(responseJson.cards.length), responseJson.cards[0], null) as ICard | null
            const setCard = checkCondition(Boolean(alaredySelectedCard), alaredySelectedCard, defaultSelectedCard) as ICard | null
            this.setState({
                cardResponse: responseJson,
                cardList: responseJson.cards,
                selectedCard: setCard
            })
        } else {
            this.setState({
                cardResponse: null,
                cardList: [],
                selectedCard: null
            })
        }
    }
    addCardApiResp = (responseJson: IAddCardResponse) => {
        this.setState({ addCardLoading: false })
        if (responseJson && 'id' in responseJson) {
            this.handleCloseAddCard()
            this.getCardsList()
            this.setState({ selectedCard: responseJson })
        } else if ('error' in responseJson) {
            this.setState({
                errorAddCard: responseJson.error
            })
        } else if ('message' in responseJson) {
            this.setState({
                errorAddCard: responseJson.message
            })
        }
    }
    getPaymentIdsApiResp = (responseJson: ICreatePaymentId[]) => {
        this.setState({ getPaymentIdsLoading: false })
        if (responseJson && responseJson.length) {
            this.setState({
                getPaymentIdsList: responseJson
            })
        } else {
            this.setState({
                getPaymentIdsList: []
            })
        }
    }
    createPaymentIdApiResp = (responseJson: ICreatePaymentResponse) => {
        this.setState({ createPaymentIdLoading: false })
        if (responseJson && 'id' in responseJson) {
            this.getPaymentIds()
            const paymentId = responseJson.id
            const customerId = responseJson.customer
            this.makePaymentCall(paymentId, customerId)
        } else if ('error' in responseJson) {
            this.setState({
                errorAddCard: responseJson.error
            })
        } else if ('message' in responseJson) {
            this.setState({
                errorAddCard: responseJson.message
            })
        } else if (responseJson?.errors && responseJson?.errors.length) {
            this.setState({
                errorAddCard: responseJson.errors[0]?.stripe
            })
        }
    }
    makePaymentApiResp = (responseJson: any, errorResponse: any) => {
        if (responseJson && responseJson.invoice) {
            defaultScroll()
            this.setState({
                paymentSuccess: true,
                paymentFailure: null
            })
            this.resetSuccessStates()
        }

        if ('error' in responseJson) {
            this.setState({
                errorAddCard: responseJson.error,
                paymentSuccess: null,
                paymentFailure: true,
                createPaymentIdLoading: false,
                makePaymentApiLoading: false,
            })
        }
        if (errorResponse) {
            this.setState({
                paymentSuccess: null,
                paymentFailure: true,
                createPaymentIdLoading: false,
                makePaymentApiLoading: false,
            })
        }
    }

    async componentDidMount() {
        defaultScroll()
        this.getCardsList()
        this.getPaymentIds()
    };

    getCardsList = () => {
        this.setState({ cardListLoading: true })
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        const header = { token: this.state.apiToken };

        const endpoint = webConfigJSON.ENDPOINTS.GET_CARD_LIST
        this.cardListApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.API_METHOD.GET);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    };

    addCard = (formData: IAddCardBody) => {
        if (formData.save_for_future) {
            let card_number = formData.number.replace(/\s+/g, '');
            let valid_till = formData.valid_till.replace(/\s+/g, '');
            this.setState({ addCardLoading: true })
            const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            const header = {
                'Content-Type': 'application/json',
                token: this.state.apiToken
            };
            const bodyData = {
                payment_method: {
                    name: formData.name,
                    number: card_number,
                    valid_till: valid_till,
                    save_for_future_use: String(formData.save_for_future)
                }
            }

            const endpoint = webConfigJSON.ENDPOINTS.ADD_CARD
            this.addCardApiCallId = reqMessage.messageId;
            reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));

            reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.API_METHOD.POST);
            runEngine.sendMessage(reqMessage.id, reqMessage);
        } else {
            this.saveCardOnLocal(formData)
        }
    }

    getPaymentIds = () => {
        this.setState({ getPaymentIdsLoading: true })
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        const header = { token: this.state.apiToken };

        const endpoint = webConfigJSON.ENDPOINTS.GET_PAYMENTIDS_LIST
        this.getPaymentIdsApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.API_METHOD.GET);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    createPaymentId = (cvv_number: string | number) => {
        const { selectedCard } = this.state
        if (!selectedCard) return

        this.setState({ createPaymentIdLoading: true })

        const header = {
            'Content-Type': 'application/json',
            token: this.state.apiToken
        };

        const validity = selectedCard.exp_month + "/" + selectedCard.exp_year
        const bodyData = {
            payment_method: {
                name: selectedCard.name,
                number: selectedCard.metadata.card_number,
                valid_till: validity,
                cvc: cvv_number
            }
        }
        const endpoint = webConfigJSON.ENDPOINTS.GET_PAYMENTIDS_LIST

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createPaymentIdApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.API_METHOD.POST);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    setNewCard = (card: ICard) => {
        this.setState({
            selectedCard: card
        })
    }
    saveCardOnLocal = (formData: IAddCardBody) => {
        const cardNumber = formData.number.replace(/\s+/g, '');
        const expMonth = Number(formData.valid_till.split(' / ')?.[0])
        const expYear = Number(formData.valid_till.split(' / ')?.[1])
        const tempCardData: ICard = {
            ...initialCard,
            brand: getCardProvider(formData.number),
            last4: formData.number.slice(-4),
            exp_month: expMonth,
            exp_year: expYear,
            name: formData.name,
            metadata: {
                card_number: cardNumber,
                save_for_future_use: String(formData.save_for_future)
            }
        }
        const isCardAlreadyExist = this.isCardInList(tempCardData)
        if (!isCardAlreadyExist) {
            this.setState({
                cardList: [...this.state.cardList, tempCardData],
                selectedCard: tempCardData
            })
            this.handleCloseAddCard()
        } else {
            this.setState({
                errorAddCard: webConfigJSON.DEFAULT_MSG.CARD_ALREADY_EXIST
            })
        }
    }
    isCardInList = (tempCard: ICard) => {
        const cloneCardList = [...this.state.cardList]
        const matchedObj = cloneCardList.find((cardRecord) =>
            cardRecord.metadata.card_number == tempCard.metadata.card_number &&
            cardRecord.exp_month == tempCard.exp_month &&
            cardRecord.exp_year == tempCard.exp_year
        )
        return matchedObj
    }
    getBodyData = () => {
        const { start_date, end_date, service_name, is_repeat_weekly, selected_repeat_days, createBookingResponse } = this.props.BookingData
        const startDate = moment(start_date).format('YYYY-MM-DD')

        let service_end_date = ""
        if (((service_name === webConfigJSON.SERVICES_ENUM.HOTELS) && end_date)) {
            service_end_date = moment(end_date).format('YYYY-MM-DD')
        }

        if (is_repeat_weekly && selected_repeat_days.length && end_date && start_date) {
            service_end_date = moment(end_date).format('YYYY-MM-DD')
        }

        let total_price = ""
        if (createBookingResponse?.first_schedule_price) {
            total_price = returnTruthyString(createBookingResponse?.first_schedule_price)
        }
        if (!createBookingResponse?.first_schedule_price && !is_repeat_weekly) {
            total_price = returnTruthyString(createBookingResponse?.total_price)
        }

        return { startDate, endDate: service_end_date, totalPrice: total_price }
    }
    makePaymentCall = (paymentId: string, customerId: string) => {
        const { startDate, endDate, totalPrice } = this.getBodyData()
        if (!paymentId || !customerId || !totalPrice) return
        this.setState({ makePaymentApiLoading: true })
        const header = {
            token: this.state.apiToken
        };
        const { service_name, createBookingResponse, is_repeat_weekly } = this.props.BookingData

        const reqFormData = new FormData()
        reqFormData.append("currency", "EUR");

        reqFormData.append("payment_method_id", paymentId);
        reqFormData.append("customer_id", customerId);
        reqFormData.append("total_price", totalPrice);
        reqFormData.append("description", service_name);
        reqFormData.append("start_date", startDate);

        if (endDate) {
            reqFormData.append("end_date", endDate);
        } else {
            reqFormData.append("end_date", startDate);
        }

        if (createBookingResponse?.schedule_id) {
            reqFormData.append("schedules_ids[]", String(createBookingResponse.schedule_id));
        } else if (createBookingResponse?.first_schedule_id) {
            reqFormData.append("schedules_ids[]", String(createBookingResponse.first_schedule_id));
        }

        if (is_repeat_weekly) {
            reqFormData.append("recurring", String(is_repeat_weekly));
        }
        const endpoint = webConfigJSON.ENDPOINTS.MAKE_PAYMENT

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.makePaymentApicallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), reqFormData);
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.API_METHOD.POST);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    onSubmitCVVmodal = (FormValues: { cvv_number: string }) => {
        const paymentIdRecord = this.findPaymentId()
        if (paymentIdRecord) {
            const paymentId = paymentIdRecord.id
            const customerId = paymentIdRecord.customer
            this.makePaymentCall(paymentId, customerId)
        } else {
            this.createPaymentId(FormValues.cvv_number)
        }
    }

    findPaymentId = () => {
        const { selectedCard, getPaymentIdsList } = this.state
        if (selectedCard && getPaymentIdsList.length) {
            const matchedRecord = getPaymentIdsList.find((records) =>
                (records.card.fingerprint == selectedCard.fingerprint) ||
                (records.metadata.card_number == selectedCard.metadata.card_number)
            )
            if (matchedRecord) {
                return matchedRecord
            }
            return null
        }
        return null
    }

    handleOpenAddCard = () => {
        this.setState({
            openAddCard: true,
            errorAddCard: ""
        })
    }
    handleCloseAddCard = () => {
        this.setState({
            openAddCard: false,
            errorAddCard: ""
        })
    }
    clearAddCardError = () => {
        this.setState({
            errorAddCard: ""
        })
    }
    handleOpenCVVmodal = () => {
        this.setState({
            openCVVmodal: true,
            errorAddCard: ""
        })
    }
    handleCloseCVVmodal = () => {
        this.setState({
            openCVVmodal: false,
            errorAddCard: ""
        })
    }
    goToHomePage = () => {
        this.props.navigation?.navigate("Home")
    }

    resetSuccessStates = () => {
        this.setState({
            // CLOSE CVV MODAL
            openCVVmodal: false,
            createPaymentIdLoading: false,
            makePaymentApiLoading: false
        })
    }
    resetPaymentStates = () => {
        this.setState({
            createPaymentIdLoading: false,
            makePaymentApiLoading: false,
            paymentSuccess: null,
        })
    }
    // Customizable Area End
}

